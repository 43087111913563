export const regionMap = [
    { title: "Africa", img: "../image/map/Africa.svg", slug: "africa" },
    {
        title: "Asia",
        img: "/image/map/Asia.svg",
        slug: "asia",
    },
    {
        title: "Central America",
        img: "/image/map/C.America.svg",
        slug: "central-america",
    },
    {
        title: "Europe",
        img: "/image/map/Europe.svg",
        slug: "europe",
    },

    {
        title: "North America",
        img: "/image/map/N.America.svg",
        slug: "north-america",
    },
    {
        title: "Oceania",
        img: "/image/map/Oceania.svg",
        slug: "oceania",
    },
    {
        title: "South America",
        img: "/image/map/S.America.svg",
        slug: "south-america",
    },
];

export const regionCountry = [
    {
        regionName: "Asia",
        countries: [
            {
                countryName: "Japan",
                img: "/image/flag/japan.svg",
                slug: "japan",
            },
            {
                countryName: "Malaysia",
                img: "/image/flag/malaysia.svg",
                slug: "malaysia",
            },
            {
                countryName: "Taiwan",
                img: "/image/flag/taiwan.svg",
                slug: "taiwan",
            },
            {
                countryName: "Vietnam",
                img: "/image/flag/vietnam.svg",
                slug: "vietnam",
            },
            {
                countryName: "Türkiye",
                img: "/image/flag/turkey.svg",
                slug: "turkey",
            },
            {
                countryName: "Thailand",
                img: "/image/flag/thailand.svg",
                slug: "thailand",
            },
        ],
    },
    {
        regionName: "Europe",
        countries: [
            {
                countryName: "Germany",
                img: "/image/flag/germany.svg",
                slug: "germany",
            },
            {
                countryName: "France",
                img: "/image/flag/france.svg",
                slug: "france",
            },
            {
                countryName: "Italy",
                img: "/image/flag/italy.svg",
                slug: "italy",
            },
            {
                countryName: "United Kingdom",
                img: "/image/flag/uk.svg",
                slug: "uk",
            },
            {
                countryName: "Greece",
                img: "/image/flag/greece.svg",
                slug: "greece",
            },
            {
                countryName: "Iceland",
                img: "/image/flag/iceland.svg",
                slug: "iceland",
            },
            {
                countryName: "Portugal",
                img: "../image/flag/portugal.svg",
                slug: "portugal",
            },
            {
                countryName: "Malta",
                img: "/image/flag/malta.svg",
                slug: "malta",
            },
            {
                countryName: "Romania",
                img: "/image/flag/romania.svg",
                slug: "romania",
            },
            {
                countryName: "Spain",
                img: "/image/flag/spain.svg",
                slug: "spain",
            },
            {
                countryName: "The Netherlands",
                img: "/image/flag/netherlands.svg",
                slug: "netherlands",
            },
            {
                countryName: "Finland",
                img: "/image/flag/finland.svg",
                slug: "finland",
            },
            {
                countryName: "Sweden",
                img: "/image/flag/sweden.svg",
                slug: "sweden",
            },
            {
                countryName: "Slovenia",
                img: "/image/flag/slovenia.svg",
                slug: "slovenia",
            },
            {
                countryName: "Croatia",
                img: "/image/flag/croatia.svg",
                slug: "croatia",
            },
            {
                countryName: "Ireland",
                img: "/image/flag/ireland.svg",
                slug: "ireland",
            },
        ],
    },
    {
        regionName: "North America",
        countries: [
            {
                countryName: "United States",
                img: "/image/flag/usa.svg",
                slug: "usa",
            },
            {
                countryName: "Canada",
                img: "/image/flag/canada.svg",
                slug: "canada",
            },
            {
                countryName: "Mexico",
                img: "/image/flag/mexico.svg",
                slug: "mexico",
            },
        ],
    },
    {
        regionName: "South America",
        countries: [
            {
                countryName: "Brazil",
                img: "/image/flag/brazil.svg",
                slug: "brazil",
            },
            {
                countryName: "Argentina",
                img: "/image/flag/argentina.svg",
                slug: "argentina",
            },
            {
                countryName: "Peru",
                img: "/image/flag/peru.svg",
                slug: "peru",
            },
            {
                countryName: "Chile",
                img: "/image/flag/chile.svg",
                slug: "chile",
            },
            {
                countryName: "Colombia",
                img: "/image/flag/colombia.svg",
                slug: "colombia",
            },
        ],
    },
    {
        regionName: "Africa",
        countries: [
            {
                countryName: "Cabo Verde",
                img: "/image/flag/cabo-verde.svg",
                slug: "cabo-verde",
            },
            {
                countryName: "South Africa",
                img: "/image/flag/south-africa.svg",
                slug: "south-africa",
            },
            {
                countryName: "Tanzania",
                img: "/image/flag/tanzania.svg",
                slug: "tanzania",
            },
        ],
    },
    {
        regionName: "Oceania",
        countries: [
            {
                countryName: "Australia",
                img: "/image/flag/australia.svg",
                slug: "australia",
            },
            {
                countryName: "New Zealand",
                img: "/image/flag/nz.svg",
                slug: "new-zeland",
            },
        ],
    },
    {
        regionName: "Central America",
        countries: [
            {
                countryName: "Costa Rica",
                img: "/image/flag/costa-rica.svg",
                slug: "costa-rica",
            },
        ],
    },
];

export const customDestinations = [
    "Argentina",
    "Borneo",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Mexico (Yucatán)",
    "Tanzania",
];
